import { b as bootstrapLazy } from './index-686d1ec5.js';
export { s as setNonce } from './index-686d1ec5.js';
import { g as globalScripts } from './app-globals-0f993ce5.js';
const defineCustomElements = async (win, options) => {
  if (typeof window === 'undefined') return undefined;
  await globalScripts();
  return bootstrapLazy([["charge-point-pricing", [[0, "charge-point-pricing"]]], ["charge-point-connector_5", [[1, "chs-charge-point", {
    "cpoiid": [1],
    "environment": [1]
  }, [[6, "cpoi_updateToken", "updateToken"]], {
    "cpoiid": ["watchPropHandler"]
  }], [2, "charge-point-connector"], [4, "charge-point-header", {
    "copied": [32]
  }], [1, "charge-point-support"], [1, "chs-loader"]]]], options);
};
export { defineCustomElements };

